@use "variables.scss" as *;
.item {
  padding: 6px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #4f5a78;

  &[data-disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.disabled:hover {
    background-color: transparent;
  }

  &.disabled.selected {
    background-color: transparent;
  }

  &.disabled.selected:hover {
    background-color: transparent;
  }

  &.disabled.selected:hover {
    background-color: transparent;
  }

  &[data-highlighted] {
    outline: none;
    //opacity: 0.5;
    & svg {
      opacity: 0.5;
    }
    & div:nth-child(2) {
      opacity: 0.5;
    }
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 16px 12px;
  gap: 2px;
  border: 1px solid #e1e3e5;
  background-color: white;
  border-radius: 2px;

  & .contentRect {
    position: absolute;
    top: -20px;
    right: 0;
  }
}
