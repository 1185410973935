@use "variables.scss" as *;
.Droparea {
  background: #f0faff;
  border: 1px dashed #bed0ff;
  border-radius: 2px;
  min-height: 118px;
  height: 100%;
  max-height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .Empty {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 11px;
      color: #3dc5ff;
      text-align: center;
    }

    svg {
      height: 40px;
      width: 40px;
    }
  }
}

.Description {
  margin-top: 8px;
  font-size: 12px;
  color: $error-border;
}
