@use "variables.scss" as *;
.FormHelper {
  position: absolute;
  right: -16px;
  top: -16px;
  bottom: 0;
  z-index: 9;

  .FormHelperButton {
    top: 60px;

    @include iftablet {
      top: 0;
    }
  }
}
