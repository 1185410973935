@use "variables.scss" as *;
.MonthWrapper {
  display: flex;
  justify-content: center;
}

.Days {
  display: flex;
  color: $grey-1;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;

  & div {
    display: flex;
    width: 28px;
    align-items: center;
    justify-content: center;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $tablet-width) {
      width: 42.5px;
    }
  }
}

.Week {
  display: flex;
}

.Day {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 3px;
  cursor: pointer;

  @media (max-width: $tablet-width) {
    width: 42.5px;
    height: 42.5px;
  }
}

.DayGrey {
  composes: Day;

  color: $grey-4;
}

.Today {
  position: relative;
  color: #00affa;

  background: url(./today.svg) no-repeat center 22px;

  @media (max-width: $tablet-width) {
    background: unset;
    &::after {
      content: "";
      width: 2px;
      height: 2px;
      bottom: 10px;
      background-color: #00affa;
      position: absolute;
      background: url(./today.svg) no-repeat bottom 22px;
    }
  }
}

.Selected {
  background: $blue-light;
}

.SelectedSingle {
  position: relative;
  background: #00affa;
  border-radius: 14px;
  color: $white-color;

  @media (max-width: $tablet-width) {
    border-radius: 50%;
  }
}

.SelectedSingleGrey {
  composes: SelectedSingle;
  background: $grey-5;
}

.SelectedLeft {
  composes: SelectedSingle;
  border-radius: 0 14px 14px 0;
  background: $blue-light;

  &::before {
    position: absolute;
    content: attr(data-date);
    background: #00affa;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 16px;

    @media (max-width: $tablet-width) {
      width: 42.5px;
      height: 42.5px;
      border-radius: 50%;
    }
  }
}

.SelectedRight {
  composes: SelectedSingle;
  border-radius: 14px 0 0 14px;
  background: $blue-light;

  &::before {
    position: absolute;
    content: attr(data-date);
    background: #00affa;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 16px;

    @media (max-width: $tablet-width) {
      width: 42.5px;
      height: 42.5px;
      border-radius: 50%;
    }
  }
}
