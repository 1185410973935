@use "variables.scss" as *;
.Root {
  position: relative;
  width: 100%;
}

.Placeholder {
  color: $grey-1;
}

.Select {
  position: relative;
  display: flex;
  min-height: 44px;
  padding: 10px 36px 10px 16px;
  background: $white-color;
  border: 1px solid $grey-4;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 14px;
  line-height: 20px;
  text-overflow: ellipsis;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: $mobile-width) {
    min-height: 36px;
    padding: 8px 36px 8px 16px;
  }
}

.Separator {
  width: 100%;
  height: 1px;
  background: $grey-4;
}

.Tags {
  display: grid;
  gap: 8px;
  grid-auto-flow: column;
}

.Tag {
  border-radius: 2px;
  padding: 1px 10px 1px 10px;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.Colorless {
  &.Select {
    color: $grey-1;
    background: transparent;
    border: none;
    height: 36px;
    padding: 8px 36px 8px 16px;

    &::after {
      top: 0.45rem;
      right: 0.45rem;
    }
  }
}

.ErrorSelect {
  border: 1px solid $error-border !important;
}

.List {
  min-width: 100%;
  margin-top: 3px;
  position: absolute;
  z-index: $z-list;
  padding: 0.25rem 0;
  background: $white-color;
  box-shadow: 0px 0px 1px rgba(128, 128, 128, 0.15),
    0px 3px 9px rgba(65, 54, 32, 0.1);
  border-radius: 0.275rem;
  transform-origin: top;
  max-height: 450px;
  overflow-y: auto;
}

.ListLimit{
  composes: List;
  max-height: 185px;
}

.ListEnter {
  transform: scaleY(0);
  transition: transform linear 150ms;
}

.ListEnterActive {
  transform: scaleY(1);
  transition: transform linear 150ms;
}

.ListExit {
  transform: scaleY(1);
}

.ListExitActive {
  transform: scaleY(0);
  transition: transform linear 150ms;
}

.Item {
  height: 2.75rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-size: 0.875rem;
  white-space: nowrap;
  cursor: pointer;
  color: #6c7278;

  &:hover {
    background: url(/_next/static/media/arrow-gray.dcfd4546.svg) no-repeat
        center right 10px,
      #f5f6f7;
    background-color: $grey-5;
  }
}

.ActiveItem {
  composes: Item;

  color: $black;
  background: url(./arrow.svg) no-repeat center right 10px, $blue-light !important;
}

.Description {
  margin-top: 0.125rem;
  font-size: 0.6875rem;
  line-height: 0.75rem;
}

.DescriptionError {
  composes: Description;

  color: $error-border;
}

.Counter {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #45494c;
}

.SelectCounter {
  composes: Select;
  &::after {
    content: "";
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    width: 1.25rem;
    height: 1.25rem;
    background: url("../assets/icons/16/arrow-down.svg") no-repeat center;
    transition: transform linear 150ms;
  }
}

.SelectCounterOpen {
  composes: SelectCounter;

  &::after {
    transform: rotate(-180deg);
  }
}

.disabled {
  background: #f5f6f7;
  cursor: default;
}
