@use "variables.scss" as *;
.Mobile {
  display: none;

  @media (max-width: $mobile-width) {
    display: block;
  }
}

.Tablet {
  display: none;

  @media (max-width: $tablet-width) {
    display: block;
  }
}

.Desktop {
  display: block;

  @media (max-width: $tablet-width) {
    display: none;
  }
}