@use "variables.scss" as *;
.tag {
  color: $black;
  background-color: #f2f2f2;
  padding: 0 8px;
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 12px;
  white-space: nowrap;
  height: 22px;

  &.Large {
    padding: 7px 17px;
    height: 34px;
    font-size: 14px;
  }
}

.Grid {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  position: relative;
}
