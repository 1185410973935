@use "variables.scss" as *;
.Subtitle {
  font-weight: 500;
  font-size: 14px;

  &.Large {
    font-size: 16px;
  }
  &.Secondary{
    color: #6c7278;
    font-size: 14px;
  }
}
