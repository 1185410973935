@use "variables.scss" as *;
.Root {
  display: flex;
  width: 100%;
}

.Step {
  position: relative;
  padding-right: 2.5rem;
  margin-right: 0.75rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.25rem;
  color: $grey-1;
  white-space: nowrap;

  &::after {
    position: absolute;
    content: "";
    width: 20px;
    height: 44px;
    background: url(./chevron.svg) no-repeat center;
    right: 0;
    top: 0;

    @media (max-width: $mobile-width) {
      height: 24px;
      left: 30px;
      top: 0;
    }
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  @media (max-width: 1000px) {
    padding-right: 12px;
  }
}

.ActiveStep {
  composes: Step;

  color: $black;

  .Circle {
    border: 2px solid $additional-2-color;
  }

  .CircleInner {
    background: $additional-color;
  }

  @media (max-width: 1000px) {
    padding-right: 16px;
  }
}

.LastStep {
  composes: Step;

  color: $black;
  cursor: pointer;

  &:hover {
    color: #a7d700;
  }

  .CircleInner {
    background: $additional-color;
  }
}

.PreviousActiveStep {
  composes: Step;

  color: $black;

  &:hover {
    color: #a7d700;
  }

  .Circle {
    border: 2px solid #a7d700;
  }

  .CircleInner {
    background: #a7d700;
  }
}

.AvailableStep {
  composes: Step;
  cursor: pointer;

  &:hover {
    color: #a7d700;
  }

  .CircleInner {
    background: #a7d700;
  }
}

.AvailableNotFilledStep {
  composes: Step;
  cursor: pointer;

  &:hover {
    color: $additional-color;
  }

  .CircleInner {
    background: $additional-color;
  }
}

.NoHoverNoActive {
  &:hover {
    color: #8a9299 !important;
    cursor: initial !important;
  }
}

.NoHoverActive {
  &:hover {
    color: inherit !important;
    cursor: initial !important;
  }
}

.Circle {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  margin-right: 0.75rem;
  flex-shrink: 0;

  &:hover {
    color: #a7d700;
  }

  @media (max-width: $mobile-width) {
    width: 24px;
    height: 24px;
  }
}

.CircleInner {
  background: $grey-3;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;

  @media (max-width: $mobile-width) {
    width: 20px;
    height: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
}

.Label {
  @media (max-width: 1000px) {
    display: none;
  }
}
