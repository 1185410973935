@use "variables.scss" as *;
.Warning {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  gap: 8px;
  background: #fffaf1;
  border: 1px solid #fbbc05;
  height: 52px;

  & .Content {
    color: #7e858c;
    font-size: 12px;
    width: 100%;
  }

  & .Close {
    cursor: pointer;
    color: #7e858c;
    font-size: 12px;
  }
}
