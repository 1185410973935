@use "variables.scss" as *;
.Root {
  position: relative;
}

.OnlyShow {
  pointer-events: none;
}

.InputWrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 6px 10px;
  border: 1px solid $grey-4;
  border-radius: 2px;
  background: $white-color;
  min-height: 44px;
}

.ActiveInputWrapper {
  composes: InputWrapper;
  border: 1px solid $blue-main-focused;
}

.Input {
  width: 90px;
  flex-grow: 1;
  border: none;
  padding: 0;
  font-size: 14px;
  line-height: 30px;
}

.Wrapper {
  position: absolute;
  width: 100%;
  z-index: 9999;
}

.List {
  margin-top: 1px;
  background: $white-color;
  box-shadow: 0px 0px 1px rgba(128, 128, 128, 0.15),
    0px 3px 9px rgba(65, 54, 32, 0.1);
  border-radius: 2px;
  border: 1px solid $grey-4;
  display: flex;
  flex-wrap: wrap;
  padding: 6px 10px;
}

.Item {
  display: flex;
  align-items: center;
  background: $grey-5;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  padding: 0px 8px;
  margin: 4px 2px;
  cursor: pointer;
}

.Item_More {
  background: $grey-5 !important;
  margin-left: 16px;
}

.Before {
  margin-right: 4px;
  display: flex;
  align-items: center;
}

.SelectedTag {
  composes: Item;

  background: #ffefe5;
  cursor: initial;
}

.Error {
  border: 1px solid $error-border;
}

.Close {
  margin-left: 10px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Placeholder {
  font-size: 14px;
  line-height: 20px;
  color: $grey-1;
  display: flex;
  align-items: center;
}

.Description {
  margin-top: 0.125rem;
  font-size: 0.6875rem;
  line-height: 0.75rem;
}

.DescriptionError {
  composes: Description;

  color: $error-border;
}

.MaxTags {
  font-size: 14px;
  line-height: 20px;
  color: $grey-0;
  width: 100%;
}

.AddTag {
  font-size: 14px;
  line-height: 20px;
  color: $blue-main-pressed;
  cursor: pointer;
}

.disabled {
  background-color: #f5f6f7 !important;
  cursor: default !important;
}
