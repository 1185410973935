@use "variables.scss" as *;
.Root {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  min-height: 44px;
  /* White */

  background: #ffffff;
  /* Grey 4 */

  border: 1px solid #e1e3e6;
  border-radius: 2px;
  width: fit-content;
  cursor: pointer;

  &.Checked {
    border: 1px solid #00affa;
    border-radius: 2px;

    .Label {
      color: $black;
    }
  }
}

.Label {
  font-size: 14px;
  color: #8a9299;
  white-space: nowrap;
}

.Checkbox {
  position: relative;
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid $grey-4;
}

.CheckboxIcon {
  position: absolute;
  bottom: -2px;
  left: -2px;
  width: 22px;
  height: 22px;
}

.disabled {
  background: #F5F6F7;
  cursor: default;
}

.ErrorDescription {
  width: 100%;
  margin-top: 0.125rem;
  font-size: 0.6875rem;
  line-height: 0.75rem;
  color: $error-border;
  border: 1px solid #ff6969 !important;
}