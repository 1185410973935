@use "variables.scss" as *;
.Root {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 11px;

  @include iftablet {
    gap: 19px;
  }
}

.Button {
  height: 36px;
  width: 36px;
  border-radius: 100%;

  &:last-child {
    margin-right: 0;
  }
}

.Client {
  display: flex;
  align-items: center;
  gap: 4px;
}

.ClientButton {
  composes: Button;
  position: relative;

  background: #e0effa;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border: 1px solid rgba(77, 91, 106, 0.1);

  &__badge {
    background: #ff7728;
    border-radius: 22px;
    bottom: -15%;
    color: #ffefe5;
    display: none;
    justify-content: center;
    align-items: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    position: absolute;
    text-transform: uppercase;
    height: 12px;
    width: 25px;
  }

  &__badge_show {
    display: flex;
  }
}

.ProfileItem {
  display: flex;
  gap: 12px;
  width: 100%;

  &_link {
    color: #4f5a78;
  }

  &_link:hover {
    color: #4f5a78;
    opacity: 0.5;
  }

  &_hide {
    display: none !important;
  }

  &__subscription_finish_item {
    opacity: 1 !important;
  }

  &__subscription_upgrade,
  &__subscription_finish {
    width: 100%;
    color: $additional-color;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }

  &__subscription_upgrade {
    background: $orange-2-color;
    text-transform: uppercase;
    padding: 11px 0;
    text-align: center;
  }

  &__subscription_finish {
    border: 1px solid #ff7728;
    border-radius: 32px;
    padding: 6px 12px;
    max-width: 190px;
    white-space: nowrap;

    &__nodata {
      width: 100%;
      color: $additional-color;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      border: 1px solid #ff7728;
      border-radius: 32px;
      padding: 6px 12px;
      max-width: 190px;
      white-space: nowrap;
      text-align: center;
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.applicationCounter {
  margin-left: auto;
  position: relative;

  &__inner {
    position: absolute;
    top: -8px;
    right: 0px;
    background: #ffde7c;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    //min-width: 20px;
    //width: max-content;
    padding: 0;
    width: 16px;
    height: 16px;
    cursor: pointer;
    //@include if360{
    //  display: none;
    //}

    span {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 9px;
      line-height: 11px;
      text-align: center;
      letter-spacing: -1px;
      text-transform: uppercase;
      color: #45494c;
      width: 100%;
    }
  }
}
