@use "variables.scss" as *;
.Label {
  font-size: 14px;
  display: flex;
  gap: 6px;
  align-items: center;
  color: #00affa;

  & .Content {
    cursor: pointer;
  }

  & .Icon {
    display: flex;
    align-items: center;

    svg {
      width: 14px;
      stroke: #8a9299;
    }
  }
}
