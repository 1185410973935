@use "variables.scss" as *;
.Notification {
  position: relative;

  font-family: Inter;
  font-style: normal;
  color: transparent;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  gap: 4px;
  border-radius: 50px;
  width: 36px;
  height: 36px;

  &:hover {
    background: #f5f6f7;

    & svg {
      & path {
        fill: #9a41d1;
      }
    }
  }
}

.MailIcon {
  width: 16px;
  height: 16px;
}
