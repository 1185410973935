@use "variables.scss" as *;
.Root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.Background {
  display: flex;
  width: 100%;
  min-height: max(100vh, 730px);
  background: url(../../assets/signup/signup-bg.jpg) center;
  background-size: cover;

  @media (max-width: $tablet-width) {
    min-height: auto;
    flex-grow: 1;
  }
}

.Border {
  position: relative;
  flex-grow: 1;
  padding: 50px 100px 50px 50px; // 50px 100px 80px 50px
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background:
    url(../../assets/signup/left-bottom-bg.svg) left bottom no-repeat,
    url(../../assets/signup/right-top-bg.svg) right top no-repeat,
    url(../../assets/signup/bottom-bg.svg) bottom repeat-x,
    url(../../assets/signup/right-bg.svg) right repeat-y;

  @media (max-width: $tablet-width) {
    padding: 24px 15px 20px 24px;
    background:
      url(../../assets/signup/left-bottom-bg-mini.svg) left bottom no-repeat,
      url(../../assets/signup/right-top-bg-mini.svg) right top no-repeat;
  }
}

.Logo {
  width: 100%;
  max-width: 209px;
  height: 40px;
  background: url(../../assets/logo.svg) no-repeat left;
  background-size: contain;
  margin-bottom: 2.75rem;
  cursor: pointer;
}

.Text {
  position: relative;
  align-self: flex-end;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: $tablet-width) {
    color: $white-color;
    align-self: flex-end;
  }
}

.Label {
  font-weight: 600;
  font-size: 24px; 
  line-height: 36px;
  margin-bottom: 10px;

  @media (max-width: $tablet-width) {
    text-align: right;
    font-size: 12px;
    line-height: 14px;
  }
}

.Description {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  @media (max-width: $tablet-width) {
    text-align: right;
    font-size: 12px;
    line-height: 14px;
  }
}

.Children {
  flex-grow: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Panel {
  align-self: flex-end;
  padding: 40px;
  background: #FFFFFF;
  box-shadow: -4px 0px 40px rgba(81, 81, 82, 0.05);

  @media (max-width: $tablet-width) {
    width: 100%;
    padding: 40px 16px;
  }
}