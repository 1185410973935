@use "variables.scss" as *;
.Root {
  position: relative;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  width: 349px;
  height: 363px;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 20px;

  .Title {
    font-weight: 600;
    margin-top: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .Description {
    margin-top: 16px;
    font-size: 14px;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    &Long {
      display: block;
    }
  }

  .Actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 16px;
    right: 16px;
    gap: 8px;
  }

  img {
    width: calc(100% + 40px);
    margin-left: -20px;
    height: 60%;
    object-fit: cover;
  }
}

.Action {
  border-radius: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.Edit {
  composes: Action;
}

.Remove {
  composes: Action;
}
