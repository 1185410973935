@use "variables.scss" as *;
.container {
  max-width: 100%;
  overflow-x: hidden;
  width: 100%;

  & .trapezoid {
    border-left: 235vw solid transparent;
    border-right: 0 solid transparent;
    height: 0;
    width: 0;
    transform: rotate(180deg);
  }

  & .large {
    clip-path: polygon(0 0, 100% 0, 100% 82%, 0 100%);
    height: calc(132px + 196px);
    width: 100%;
  }
}
