@use "variables.scss" as *;
.Tooltip {
  background: #45494c !important;
  border-radius: 6px;
  padding: 8px 12px;
  color: rgba(255, 255, 255, 1);
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
  font-size: 12px !important;
  z-index: 99999;
  left: 0 !important;
  opacity: 1 !important;
  pointer-events: auto !important;
}

.Arrow {
  margin-top: -1px;
}
