@use "variables.scss" as *;
.Root {
  position: relative;
  width: 100%;
}

.Input {
  padding: 0 16px;
  width: 100%;
  height: 44px !important;
  background: $white-color;
  border: 1px solid $grey-4;
  border-radius: 2px;
  font-size: 14px;
  line-height: 20px;
  text-overflow: ellipsis;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  @media (max-width: $mobile-width) {
    height: 2.25rem;
  }

  &:hover {
    border: 1px solid $blue-focus;
  }

  &:focus {
    border: 1px solid $blue-focus;
    box-shadow: 0px 0px 0px 2px #ddf2fe;
  }

  &::placeholder {
    color: $grey-1;
  }

  &:disabled {
    color: $grey-2;
    background: $grey-5;

    &:hover {
      border: 1px solid $grey-4;
    }
  }
}

.InputError {
  composes: Input;

  border: 1px solid $error-border !important;
}

.Icon {
  position: absolute;
  user-select: none;
  pointer-events: none;
}

.IconClickable {
  composes: Icon;

  cursor: pointer;
  user-select: none;
  pointer-events: all;
}

.RightIcon {
  top: 0.875rem;
  right: 0.875rem;
}

.LeftIcon {
  top: 0.75rem;
  left: 0.7rem;

  @media (max-width: $mobile-width) {
    top: 12px;
    left: 8px;
  }
}

.DescriptionError {
  color: $error-border;
}

.RightIconInput {
  padding-right: 30px;
}

.LeftIconInput {
  padding-left: 40px;
}

.Description {
  margin-top: 0.125rem;
  font-size: 0.6875rem;
  line-height: 0.75rem;
  color: #8a9299;
}

.DescriptionError {
  composes: Description;

  color: $error-border;
}
