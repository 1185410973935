@use "variables.scss" as *;
.Description {
  color: $grey-1;
}

.Format {
  color: $black;
}

.Extension {
  font-size: 12px;
}
