@use "variables.scss" as *;
.Root {
  min-height: 100vh;
}

.RootBrand {
  composes: Root;

  background: url("./images/brand.png") no-repeat 700px center, white;

  @media (max-width: $desktop-width) {
    background: white;
  }
}

.RootAgency {
  composes: Root;

  background: url("./images/agency.png") no-repeat 700px center, white;

  @media (max-width: $desktop-width) {
    background: white;
  }
}

.RootSpot {
  composes: Root;

  background: url("./images/spot.png") no-repeat 700px center, white;

  @media (max-width: $desktop-width) {
    background: white;
  }
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.25rem;
  padding: 0 1.25rem;
  box-shadow: inset 0px -1px 0px #EAEBEC;
}

.Logo {
  width: 100%;
  max-width: 126px;
  height: 40px;
  background: url(../../assets//logo.svg) no-repeat left center;
  background-size: contain;
}

.Main {
  padding: 3.125rem;
}