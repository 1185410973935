@use "variables.scss" as *;
.Root {
  display: flex;
  align-items: flex-start;
  width: fit-content;
}

.Input {
  border: none;
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  opacity: 0;
}

.Checkbox {
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
  border-radius: 0.125rem;

  border: 1px solid $grey-4;
}

.CheckboxError {
  composes: Checkbox;

  border: 1px solid $error-border !important;
}

.Input:hover + .Checkbox {
  background: $blue-main-light-color;
  border: 1px solid $blue-main-focused;
}

.Input:focus + .Checkbox {
  background: $blue-main-light-color;
  border: 1px solid $blue-main-focused;
}

.Input:checked + .Checkbox {
  background: url(./checkmart.svg) center no-repeat, $blue-main-color;
}

.Input:checked:hover + .Checkbox {
  background: url(./checkmart.svg) center no-repeat, $blue-main-dark-color;
}

.Input:disabled + .Checkbox {
  background: $grey-5;
  border: 1px solid $grey-4;
}

.Input:disabled:checked + .Checkbox {
  background: url(./checkmart.svg) center no-repeat, $grey-4;
  border: 1px solid transparent;
}

.Lable {
  margin-left: 0.5rem;
}

.LableError {
  composes: Lable;
  color: $error-border !important;
}
