@use "variables.scss" as *;
.Root {
  position: relative;
  width: 100%;
  border: 1px solid $grey-4;
  border-radius: 2px;
  background-color: white;
  height: 100%;

  &:hover {
    border: 1px solid $blue-focus;
  }

  &:focus-within {
    border: 1px solid $blue-focus;
    box-shadow: 0px 0px 0px 2px #ddf2fe;
  }
}

.Container {
  height: 100%;
}

.RootError {
  composes: Root;

  border: 1px solid $error-border !important;
}

.Input {
  padding: 0.75rem 1rem;
  width: 100%;
  background: $white-color;
  border: none;
  font-size: 0.875rem;
  min-height: 40px;
  max-height: 100%;

  &::placeholder {
    color: $grey-1;
  }
}

.InputCount {
  padding-bottom: 0;
  // margin-bottom: 18px;
}

.Description {
  margin-top: 0.125rem;
  font-size: 0.6875rem;
  line-height: 0.75rem;
}

.DescriptionError {
  composes: Description;

  color: $error-border;
}

.Count {
  position: absolute;
  text-align: right;
  margin-top: 5px;
  font-size: 11px;
  line-height: 12px;
  right: 8px;
  bottom: 8px;
}

.CountWarning {
  color: #fbbc05;
}

.CountError {
  color: #ff6969;
}
