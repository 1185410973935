@use "variables.scss" as *;
.Root {
  position: relative;
  display: flex;
  width: 100%;
}

.SecondaryRoot {
  composes: Root;

  .ActiveTab {
    color: $coral;
    border-bottom-color: $coral;
  }
}

.Tab {
  width: 100%;
  display: flex;
  color: $grey-1;
  border-bottom: 2px solid $grey-4;
  justify-content: center;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
  cursor: pointer;
}

.ActiveTab {
  composes: Tab;

  color: $blue-tab;
  border-bottom-color: $blue-tab;
  cursor: inherit;
}
