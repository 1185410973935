@use "variables.scss" as *;
.container {
  position: relative;
  width: fit-content;
  background: #ffffff;
  outline: 1px solid #d9d9d9;

  &.hiddenBorder {
    border: none;
  }

  &.small {
    width: 120px;
    height: 120px;
  }

  &.large {
    min-width: 180px;
    width: 180px;
    height: 180px;
  }

  & .picture {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  & .iconContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    & .icon {
      width: 30%;
      height: 30%;
      fill: #e1e3e6;
    }
  }
}

.Grid {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  flex-wrap: wrap;
  max-width: 600px;
  gap: 1px;

  > div {
    width: 180px !important;
    height: auto !important;

    img {
      aspect-ratio: 1/1;
    }
  }

  @include iftablet {
    grid-template-columns: repeat(auto-fit, 120px);

    > div {
      width: 120px !important;
      height: 120px !important;
    }
  }
}
