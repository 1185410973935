@use "variables.scss" as *;
.Root {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @include iftablet {
    justify-content: center;
  }
  z-index: $z-dialog;

  @media (max-width: $mobile-width) {
    align-items: flex-end;
    height: auto;
    bottom: 72px;
  }
}

.Close {
  position: sticky;
  width: 36px;
  height: 36px;
  background: url(./close.svg) no-repeat center;
  right: 0;
  top: 24px;
  cursor: pointer;
  align-self: flex-start;
}

.Dialog {
  position: relative;
  padding-top: 0;
  padding: 20px;
  padding-top: 13px;
  padding-bottom: 32px;
  overflow: auto;
  max-height: 90vh;
  width: 100%;
  max-width: 600px; //old 540px
  background: #ffffff;
  box-shadow: 0px 2px 1px rgba(65, 54, 32, 0.02),
    0px 6px 24px rgba(65, 54, 32, 0.05), 0px 6px 10px rgba(65, 54, 32, 0.1);
  border-radius: 4px;
  color: #1a1a1a;
  overflow-x: hidden;

  @include iftablet {
    padding: 32px;
    padding-top: 10px;
  }

  @media (max-width: $mobile-width) {
    border-radius: 4px 4px 0 0;
    box-shadow: none;
  }
}

.Header {
  background: white;
  position: sticky;
  top: -13px;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: $black;
  padding-top: 13px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  z-index: 1;
  justify-content: space-between;
  margin-left: -20px;
  padding-left: 20px;
  width: calc(100% + 40px);

  @include iftablet {
    padding-top: 24px;
    padding-bottom: 24px;
    margin-left: -32px;
    padding-right: 24px;
    padding-left: 32px;
    width: calc(100% + 64px);
  }
}
