@use "variables.scss" as *;
.DropZone {
  width: 120px;
  height: 120px;
  border: 1px dashed #BED0FF;
  border-radius: 20px;
  background: url('./plus.svg') no-repeat center, $blue-light;
  cursor: pointer;
}

.DropZoneResponsive {
  composes: DropZone;
  width: 100%;
  background: url('./photo.svg') no-repeat center, $blue-light;
}

.Description {
  margin-top: 0.5rem;
}

.DescriptionField {
  margin-top: 0.5rem;
  font-size: 0.6875rem;
  line-height: 0.75rem;
}

.DescriptionError {
  composes: DescriptionField;

  color: $error-border;
}