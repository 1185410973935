@use "variables.scss" as *;
.Card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border: none;
  padding: 0;
  border-radius: 2px;

  @include iftablet {
    background: #fafafa;
    padding: 24px 32px 32px;
    border: 1px solid #f5f6f7;
  }
}

.Row {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @include iftablet {
    display: grid;
    grid-template-columns: 1.4fr 1fr;
  }

  > div {
    display: flex;
    gap: 6px;
    flex-direction: column;
  }
}

.FullRow {
  display: flex;
  gap: 6px;
  flex-direction: column;
}

.HalfRow {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @include ifdesktop {
    display: grid;
    grid-template-columns: minmax(486px, 1fr) 1fr;
  }

  > div {
    display: flex;
    gap: 6px;
    flex-direction: column;
  }
}

.FlexibleRow {
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  width: 100%;

  @include iftablet {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px 24px;
  }

  @include iflarge {
    display: grid;
    grid-template-columns: minmax(486px, 1.0125fr) 1fr;
  }

  > div {
    display: flex;
    gap: 6px;
    flex-direction: column;
  }
}

.Stepper {
  display: flex;
  gap: 24px;
  flex-direction: column;

  .Step {
    margin-left: 6px;
    display: flex;
    gap: 12px;
    cursor: pointer;
    color: #222426;
    align-items: center;
    font-size: 14px;
    font-weight: 500;

    &:hover {
      color: #a7d700;
    }

    &Circle {
      aspect-ratio: 1/1;
      position: relative;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      background: #d8d9da;
      align-items: center;
      justify-content: center;
      color: #bdc1c6;
      font-size: 12px;
      font-weight: 600;

      &:has(.StepCircleCurrent) {
        color: #bdc1c6;
      }

      &Current {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scale(1.6);
        border-radius: 50%;
        transition: opacity 0.2s ease-in-out;
        border: 1px solid #bdc1c6;
        opacity: 0.5;
      }

      &Partial {
        background: #ff7728;
        &:has(.StepCircleCurrent) {
          background: #ff7728;
        }
        & .StepCircleCurrent {
          border: 1px solid #ff7728;
        }
      }

      &Filled {
        background: #a7d700;

        &:has(.StepCircleCurrent) {
          background: #a7d700;
        }

        & .StepCircleCurrent {
          border: 1px solid #a7d700;
        }
      }
    }
  }
}

.Root {
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 18px 20px;

  @include iftablet {
    display: grid;
    grid-template:
      "header right"
      "content right";
    grid-template-columns: 1fr 196px;
    padding: 60px 24px;
    padding-right: 0;
    gap: 36px;
  }

  @include ifdesktop {
    grid-template-columns: 1fr 264px;
    grid-template-rows: 0.01fr 1fr;
    padding: 60px 120px;
  }

  .Header {
    grid-area: header;
    display: flex;
    gap: 16px;
    justify-content: space-between;

    > span {
      width: 60%;
      font-weight: 600;
    }

    @include iftablet {
      align-items: center;

      > span {
        width: auto;
      }
    }

    @include ifdesktop {
      margin-bottom: 6px;
    }
  }

  .Content {
    position: relative;
    grid-area: content;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .Right {
    position: absolute;
    right: 20px;
    display: flex;
    align-self: flex-start;
    grid-area: right;
    flex-direction: column;
    gap: 24px;
    top: 76px;

    @include iftablet {
      position: sticky;
      top: 80px;
    }

    @include ifdesktop {
      margin-left: 66px;
    }
  }
}

.Header {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 36px !important;

  @include iftablet {
    justify-content: space-between;
    flex-direction: row;
  }

  > span {
    font-weight: 600;
    font-size: 20px;
    color: #222426;
    display: inline-flex;
    align-items: center;
    gap: 24px;
    line-height: 24px;

    @include iftablet {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
    }

    @include ifdesktop {
      line-height: 36px;
      font-size: 32px;
    }
  }

  span {
    gap: 24px;
    display: flex;
    align-items: center;
  }
}

.Title {
  font-weight: 600;
  font-size: 18px;

  @include iftablet {
    font-size: 20px;
  }
}
