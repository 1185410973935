@use "variables.scss" as *;
.Title {
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;

  &.Large {
    font-size: 24px;

    @include iftablet {
      font-size: 28px;
    }
  }
}
