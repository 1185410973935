@use "variables.scss" as *;
.Root {
  width: 100%;
  position: relative;

  & input {
    caret-color: transparent;
  }
}

.Calendar {
  margin-top: 19px;
  position: absolute;
  //width: 570px;
  background: #ffffff;
  box-shadow: 0px 3px 16px rgba(77, 91, 106, 0.2);
  border-radius: 4px;
  margin-left: -10px;
  z-index: 9999;

  &::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 11px;
    background: url("./triangle.svg") no-repeat center center;
    top: -11px;
    left: 120px;

    @media (max-width: $tablet-width) {
      left: 100px;
    }
  }

  @media (max-width: $tablet-width) {
    width: 393px / 420px * 100vw;
    &:last-child {
      margin-left: 0;
    }
  }

  @media (max-width: $mobile-width) {
    width: 376.925px / 420px * 100vw;
  }
}

.CalendarTop {
  composes: Calendar;
  margin-top: 0;
  top: -319px;

  &::before {
    transform: rotate(180deg);
    top: auto;
    bottom: -11px;
  }
}

.Row {
  display: flex;

  @media (max-width: $tablet-width) {
    flex-direction: column;
  }
}

.Buttons {
  display: flex;
  justify-content: flex-end;
  padding: 0 12px 9px;

  & > * {
    font-size: 12px;
    line-height: 14px;
    font-family: "Inter";
  }
}

.Col {
  border-right: 1px solid $grey-5;

  @media (max-width: $tablet-width) {
    border-right: none;
  }
}

.Menu {
  composes: Col;

  padding: 16px 12px;
  width: 128px;

  @media (max-width: $tablet-width) {
    border-bottom: 1px solid #f5f6f7;
    margin: 0 12px;
    padding: 16px 0;
    width: 93%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 6px;
  }

  &_disable {
    display: none;
  }
}

.MonthCaledar {
  composes: Col;

  padding: 17px 12px 0px;

  @media (max-width: $tablet-width) {
    &:last-child {
      display: none;
      padding: 17px 16px 0px;
    }
  }
}

.Item {
  color: $grey-1;
  font-size: 12px;
  line-height: 14px;
  padding: 2px 6px;
  margin-bottom: 10px;
  border-radius: 8px;
  cursor: pointer;
  text-wrap: nowrap;
  white-space: nowrap;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: $tablet-width) {
    width: 155px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
  }
}

.ItemActive {
  composes: Item;
  white-space: nowrap;

  background: $blue-main-pressed;
  color: $white-color;
}

.MonthActions {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  @media (max-width: $tablet-width) {
    padding: 0 20px;
  }
}

.Button {
  width: 16px;
  height: 16px;
}

.LeftButton {
  composes: Button;

  background: url(./arrow-left.svg);
  cursor: pointer;
}

.RightButton {
  composes: Button;

  background: url(./arrow-right.svg);
  cursor: pointer;
}
