@use "variables.scss" as *;
.Footer {
  position: relative;
  background: #E2F890;
  height: 80px;
  clip-path: polygon(0 22%, 100% 0, 100% 100%, 0 100%);
  margin-bottom: 72px;

  @include iftablet {
    height: 60px;
    margin-bottom: 0;
  }

  @include ifdesktop {
    clip-path: polygon(0 75%, 100% 0, 100% 100%, 0 100%);
    height: 80px;
  }
}

.Block {
  position: absolute;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: $black-light;
  gap: 8px;
  bottom: 16px;
  right: 19px;
  text-align: right;

  a {
    all: unset;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  @include iftablet {
    bottom: 16px;
    right: 25px;
    flex-direction: row;
    gap: 24px;
  }

  @include ifdesktop {
    bottom: 28px;
    right: 120px;
    flex-direction: row;
    gap: 32px;
  }
}
