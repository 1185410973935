@use "variables.scss" as *;
html {
  height: -webkit-fill-available;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 16px;
  line-height: 1.25em;
  background-color: $main-background;
}

button {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

#__next {
  min-height: 100vh;
}

textarea {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: $blue-main;
  }
}

* {
  box-sizing: border-box;
}

#_next {
  min-height: 100vh;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

a {
  color: $blue-pressed;
}

h1 {
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.25rem;
  padding-bottom: 1rem;

  @media (max-width: $mobile-width) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
}

.form-control {
}

.form-control-label {
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: $black;
}

ul, ol {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

p {
  padding: 0;
  margin: 0;
}

input {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

textarea {
  resize: none;
}

.rsw-editor {
  overflow: auto !important;
  min-height: 200px !important;
}

.rsw-toolbar {
  position: sticky;
  top: 0;
}

.rsw-ce {
  word-break: break-word;
  padding: 0.75rem 1rem !important;
  font-size: 14px;
}

.rsw-editor {
  border-radius: 2px !important;
}

.body-overflow {
  overflow: hidden;
  position: static;
}

// color picker
.w-color-alpha-fill {
  height: 80% !important;
  width: 4px !important;
  transform: translate(-50%, 10%) !important;
  border-radius: 1px !important;
}

.w-color-alpha-pointer {
  height: 100% !important;
}

body *::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
  overflow: hidden;
  border: solid 3px transparent;
}

body *::-webkit-scrollbar-thumb {
  background: #1a1a1a1a;
  border-radius: 100px;
  border: solid 3px transparent;
}

body *::-webkit-scrollbar-track {
  border: solid 3px transparent;
}
