@use "variables.scss" as *;
.Root {
  position: relative;
}

.List {
  width: 100%;
  margin-top: 2px;
  position: absolute;
  background: $white-color;
  box-shadow: 0px 0px 1px rgba(128, 128, 128, 0.15), 0px 3px 9px rgba(65, 54, 32, 0.1);
  border-radius: 6px;
  padding: 4px 0;
  z-index: 1;
}

.Item {
  height: 44px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: $grey-0;
  cursor: pointer;
}

.ItemHelp {
  composes: Item;
  cursor: inherit;
}