@use "variables.scss" as *;
.Switch {
  min-width: 40px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;

  &.Loading {
    pointer-events: none;
    .Slider {
      animation: pulsate 2s infinite;
    }
  }

  & input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .Slider {
      background-color: $blue-main-color;
    }

    &:focus + .Slider {
      box-shadow: 0 0 1px $blue-main-color;
    }

    &:checked + .Slider:before {
      transform: translateX(20px);
    }
  }
}

.Slider {
  width: 40px;
  height: 20px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.3s;
    border-radius: 50%;
  }
}

.Label {
  margin-left: 40px;
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.Description {
  margin-top: 0.125rem;
  font-size: 0.6875rem;
  line-height: 0.75rem;
}

.DescriptionError {
  composes: Description;

  color: $error-border;
}

@keyframes pulsate {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
}
