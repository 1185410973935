@use "variables.scss" as *;
.Root {
  display: flex;
  gap: 8px;
  align-items: center;

  .Progress {
    height: 44px;
    width: 44px;
  }

  .Label {
    color: #6c7278;
    font-weight: 500;
  }

  &Small {
    .Progress {
      height: 33px;
      width: 33px;
    }

    .Label {
      font-size: 12px;
      font-weight: 400;
    }
  }
}
