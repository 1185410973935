@use "variables.scss" as *;
.Description {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $grey-1;
}

.Buttons {
  margin-top: 2rem;
  display: flex;
}

.Button {
  display: inline;
  margin-right: 0.75rem;

  &:last-child {
    margin-right: 0;
  }
}