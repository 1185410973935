@use "variables.scss" as *;
.Button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid transparent;
  width: fit-content;

  &:focus {
    border: 1px solid $blue-focus;
    box-shadow: 0px 0px 0px 2px #ddf2fe;
  }

  &:disabled:not(.Locked) {
    opacity: 0.5;
    cursor: inherit;
  }
}
.LockedIconWrap{
  background-color: white;
  box-shadow: 0px 3px 9px 0px rgba(65, 54, 32, 0.10), 0px 0px 1px 0px rgba(128, 128, 128, 0.15);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  z-index: 2;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  &.LockedIconWrapLeft{
    right: auto;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.PrimaryButton {
  background: $primary-color;
  color: $white-color;

  & .LockedIconWrap svg path{
    fill: $primary-color;
  }

  &:active:not(:disabled) {
    background: $primary-pressed;
  }

  &:hover:not(:disabled) {
    background: $primary-hover;
  }
}

.OrangeButton {
  background: $orange-color;
  color: $white-color;

  & .LockedIconWrap svg path{
    fill:  $orange-color;
  }

  &:active:not(:disabled) {
    background: $orange-pressed;
  }

  &:hover:not(:disabled) {
    background: $orange-hover;
  }
}

.Orange2Button {
  background: $orange-2-color;
  color: #ff7728;

  & .LockedIconWrap svg path{
    fill: $orange-2-color;
  }

  &:active:not(:disabled) {
    background: $orange-2-pressed;
  }

  &:hover:not(:disabled) {
    background: $orange-2-hover;
  }
}

.Orange3Button {
  background: #FF7728;
  color: $white-color;
  box-shadow: 0px 8px 20px 0px rgba(86, 87, 118, 0.25), 0px 4px 6px 0px rgba(159, 186, 255, 0.32);

  & .LockedIconWrap svg path{
    fill:  $orange-color;
  }

  &:active:not(:disabled) {
    background: $orange-pressed;
  }

  &:hover:not(:disabled) {
    background: $orange-hover;
  }
}

.Primary2Button {
  background: $primary-2-color;
  color: $white-color;

  & .LockedIconWrap svg path{
    fill: $primary-2-color;
  }

  &:active:not(:disabled) {
    background: $primary-2-pressed;
  }

  &:hover:not(:disabled) {
    background: $primary-2-hover;
  }
}

.Primary3Button {
  background: $primary-3-color;
  color: $white-color;

  & .LockedIconWrap svg path{
    fill: $primary-3-color;
  }

  &:active:not(:disabled) {
    background: $primary-3-pressed;
  }

  &:hover:not(:disabled) {
    background: $primary-3-hover;
  }
}

.SecondaryButton {
  background: $secondary-color;
  color: #4d566d;

  & .LockedIconWrap svg path{
    fill: $secondary-color;
  }

  &:active:not(:disabled) {
    background: $secondary-pressed;
  }

  &:hover:not(:disabled) {
    background: $secondary-hover;
  }
}

.Secondary2Button {
  background: $secondary-2-color;
  color: #7528a4;

  & .LockedIconWrap svg path{
    fill: $secondary-2-color;
  }

  &:active:not(:disabled) {
    background: $secondary-2-pressed;
  }

  &:hover:not(:disabled) {
    background: $secondary-2-hover;
  }
}

.Secondary3Button {
  background: transparent;
  color: $blue-main;
  & .LockedIconWrap svg path{
    fill: $blue-main;
  }

  &:active:not(:disabled),
  &:focus:not(:disabled) {
    background: $blue-light;
  }

  &:hover:not(:disabled) {
    background: $blue-light;
  }
}

.Secondary4Button {
  background: transparent;
  color: $grey-0;

  & .LockedIconWrap svg path{
    fill: $grey-0;
  }

  &:active:not(:disabled),
  &:focus:not(:disabled) {
    background: $blue-light;
  }

  &:hover:not(:disabled) {
    background: $blue-light;
  }
}

.Secondary5Button {
  background: #F1FAFF;
  color: #4D566D;
  box-shadow: 0px 8px 20px 0px rgba(86, 87, 118, 0.25), 0px 4px 6px 0px rgba(159, 186, 255, 0.32);

  & .LockedIconWrap svg path{
    fill: $secondary-color;
  }

  &:active:not(:disabled) {
    background: #e9f2ff;
  }

  &:hover:not(:disabled) {
    background: #e9f2ff;
  }
}

.GreenButton {
  background: $success-border;
  color: $white-color;

  & .LockedIconWrap svg path{
    fill: $success-border;
  }

  &:active:not(:disabled) {
    background: $green-2;
  }

  &:hover:not(:disabled) {
    background: $success-border;
  }
}

.LargeButton {
  height: 52px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding: 0px 24px;
}

.MediumButton {
  height: 44px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding: 0 16px;
}

.SmallButton {
  height: 36px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 0 11px;
}

.ResponsiveButton {
  width: 100%;
}

.Icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
