@use "variables.scss" as *;
.ColorPicker {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  background: #ffffff;
  border: 1px solid #d8d9da;
  border-radius: 2px;
  max-width: 470px;
  max-height: 320px;

  @include iftablet {
    flex-direction: row;
  }

  .Saturation {
    max-height: 116px;
    min-height: 116px;
    width: 100% !important;
    height: 100% !important;
    aspect-ratio: 190/116;
    border-radius: 2px !important;

    @include iftablet {
      width: auto !important;
      height: auto;
    }
  }

  .Right {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: 100%;

    .Rgba {
      display: grid;
      grid-template-columns: 1fr 6fr;
      align-items: center;
      gap: 16px;

      span {
        font-size: 14px;
        font-weight: 400;
        color: #222426;
      }

      .RgbaInput {
        display: flex;
        flex-direction: row;
        gap: 8px;

        span {
          display: none;
        }

        input {
          height: 36px;
          width: 57px;
          text-align: center;
          width: 100%;
          border: 1px solid #e1e3e6 !important;
          border-radius: 2px;
          box-shadow: none !important;
          font-size: 14px !important;
          &::first-letter {
            color: #9ca3af;
          }
        }
      }
    }

    .HexInput {
      display: grid !important;
      grid-template-columns: 1fr 6fr;
      gap: 16px;

      span {
        color: #222426 !important;
        text-transform: uppercase !important;
        font-size: 14px;
        order: -1;
      }

      input {
        padding: 8px 16px !important;
        border: 1px solid #e1e3e6 !important;
        border-radius: 2px;
        font-size: 14px !important;
        box-shadow: none !important;
        &::first-letter {
          color: #9ca3af;
        }
      }
    }

    .Hue {
      display: flex;
      gap: 8px;

      .HuePicker {
        width: 100% !important;
        height: 100% !important;
        min-height: 28px;
        &:first-child {
          > :first-child {
            border-radius: 2px !important;
          }
        }
      }

      .HueColor {
        aspect-ratio: 1/1;
        width: 28px;
        height: 28px;
        border-radius: 2px;
      }
    }
  }
}
