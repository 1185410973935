@use "variables.scss" as *;
.Root {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Spinner {
  pointer-events: none;
  width: 2.5em;
  height: 2.5em;
  border: 0.4em solid transparent;
  border-color: #eee;
  border-top-color: $coral;
  border-radius: 50%;
  animation: Loadingspin 1s linear infinite;
}

@keyframes Loadingspin {
  100% {
    transform: rotate(360deg);
  }
}
