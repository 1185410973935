@use "variables.scss" as *;
.Model {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -6px;

  &__list {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #222426;
  }
}
